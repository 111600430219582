<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Registration code</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent="register" v-model="isFormValid">
              <v-text-field
                class="mb-4"
                spellcheck="false"
                v-model="code"
                label="Code"
                ref="code"
                :rules="registrationRules"
              />

              <v-btn
                type="submit"
                color="success"
                :disabled="!isFormValid"
                :loading="loading"
              >
                Register
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Registration",

  data() {
    return {
      code: "",
      badCode: "",
      isFormValid: false,
      registrationRules: [v => this.validateCode(v)]
    };
  },

  methods: {
    register() {
      this.$store.dispatch("registration/getRegistrationData", this.code);
    },

    validateCode(value) {
      if (!value) {
        return "Registration code required";
      }

      if (this.badCode && this.badCode === value) {
        return "Invalid code";
      }

      return true;
    }
  },

  mounted() {
    if (this.$route.params?.badCode) {
      this.badCode = this.$route.params.badCode;
      this.code = this.badCode;
    }
  },

  computed: {
    ...mapState("registration", [
      "loading",
      "notFound",
      "status",
      "registration"
    ])
  },

  watch: {
    notFound(newValue) {
      if (newValue) {
        this.badCode = this.code;
      }

      if (!this.loading) {
        this.$refs.form.validate();
      }
    },

    status(newValue) {
      this.$router.push({
        name: newValue.status,
        params: { code: this.code, email: newValue?.email }
      });
    },

    registration(newValue) {
      if (newValue.payee.payee_id) {
        this.$router.push({
          name: "TheaterContract",
          params: { code: this.code }
        });
      }
    }
  }
};
</script>

<style></style>
